
body.form-body {
    background:  url("https://t4.ftcdn.net/jpg/02/15/64/95/360_F_215649564_1qIEiuNHAwcE3QbdDwLpK4vG4C14qS9i.jpg") no-repeat top center;
    background-size: cover;
    background-repeat: repeat;
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body.form-body h1{
    text-align: center;
    margin-bottom: 25px;
  }
.wrapper-form {
  position: absolute;
  top: 5%;
  left: 10%;
  right: 10%;
  width: 80%;
  background: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

}


.wrapper .title h1{
  text-align: center;
  margin-bottom: 25px;
}

.input-fields .input,
.msg textarea{
  margin: 10px 0;
  background: transparent;
  border: 0px;
  border-bottom: 2px solid #d0fce2;
  padding: 10px;
  color: #d0fce2;
  width: 100%;
}
