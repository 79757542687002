h4 {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 600;
  }
  
  h4.no-results {
    margin-top: 10px;
    font-size: 14px;
    color: #ED5565;
    text-align: center;
    font-style: italic;
  }

  h4.message {
    margin-top: 7px;
    font-size: 14px;
    color: #ED5565;
    font-style: italic;
    border: none;
  }