.float-right {
  float: right;
}
a {
  text-decoration: none;
}

.text-right {
  text-align: right;
}

.metismenu .plus-minus,
.metismenu .plus-times {
  float: right;
}
.metismenu .arrow {
  float: right;
  line-height: 1.42857;
}
.metismenu .glyphicon.arrow:before {
  content: '\e079';
}
.metismenu .mm-active > a > .glyphicon.arrow:before {
  content: '\e114';
}
.metismenu .fa.arrow:before {
  content: '\f104';
}
.metismenu .mm-active > a > .fa.arrow:before {
  content: '\f107';
}
.metismenu .ion.arrow:before {
  content: '\f3d2';
}
.metismenu .mm-active > a > .ion.arrow:before {
  content: '\f3d0';
}
.metismenu .fa.plus-minus:before,
.metismenu .fa.plus-times:before {
  content: '\f067';
}
.metismenu .mm-active > a > .fa.plus-times {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.metismenu .mm-active > a > .fa.plus-minus:before {
  content: '\f068';
}

.nav {
  padding-right: unset;
}
.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 55px;
  padding-right: 30px;
}

.mini-navbar .nav-second-level.nav.mm-collapse li a span {
  display: block !important;
}

.middle-box h1 {
  font-size: 116px;
}
.form-group {
  margin-bottom: 1rem;
}

.ibox-title {
  padding: 15px 20px 8px 15px;
}

.m-r-xxl {
  margin-right: 65px;
}
.m-b-xxl {
  margin-bottom: 60px;
}

@font-face {
  font-family: 'Mehr Nastaleeq';
  src: url('./fonts/MehrNastaliqWebRegular.ttf') format('woff');
}

.ur {
  font-family: 'Mehr Nastaleeq';
}

/* Style to get the traget value */
button span {
  pointer-events: none;
}

.float-left {
  float: left;
}

.text-error {
  color: #dc3545;
}

/* Make check box big */
.big-checkbox {
  padding-left: 18px;
  padding-bottom: 18px;
}
/* Adjust label with check box big */
.set-label {
  padding-top: 3px;
  width: 150px;
}
.cursor-pointer {
  cursor: pointer;
}
.table-hover:hover {
  cursor: pointer;
}

/* Urdu Bread Crumb*/
.breadcrumb-item-ur + .breadcrumb-item-ur {
  padding-right: .5rem;
}
.breadcrumb-item-ur + .breadcrumb-item-ur::before {
  float: right;
  padding-left: .5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/");
}

/* Pagination Active Page Class */
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}

.caret-styles {
  display : inline-grid;
  font-size : 16px;
}

.m-t-n-3px {
  margin-top: -3px;
}

.p-t-custom{
  padding-top: 18px !important;
}

.highlight-row{
  background-color: skyblue;
}

.highlight-row-officials{
  background-color: #6ee0be;
}

.navbar-form-custom {
  width: 316px;
}

.border-none {
  border: none;
}
.list-unstyled {
  padding-right: 0;
} 

.ur-btn-group{
  direction: ltr;
}
.reponsibility-modal {
  width: 1000px;
}
.reponsibility-modal-ur {
  width: 1000px;
  font-family: 'Mehr Nastaleeq';
}

.m-l-4xl {
  margin-left: 80px;
}

.m-r-4xl {
  margin-right: 70px;
}

#root {
  color: black;
}

.nav-tabs .active.nav-link span {
  color: black;
}

.nav-tabs .nav-link span {
  color: rgb(88, 88, 88);
}

.nav-tabs .nav-link span:hover {
  color: rgb(39, 39, 39);
}

.ibox .react-datepicker__input-container ::placeholder {
  color: rgb(95, 95, 95);
}

.text-muted span {  
  color: rgb(71, 71, 71);
}

.middle-box .mb-3 ::placeholder { 
  color: rgb(71, 71, 71);
}

.modal-body .react-datepicker__input-container ::placeholder {  
  color: rgb(95, 95, 95);
}

.ibox .css-14el2xx-placeholder {
  color: rgb(95, 95, 95);
}

.gray-bg .navbar-header ::placeholder {
  color: rgb(95, 95, 95);
}

.gray-bg .nav li a i{
  color: rgb(95, 95, 95);
}

.gray-bg .nav li a span{
  color: rgb(95, 95, 95);
}

.panel-body h4 span {
  font-weight: 500;
}
 
.pt-7 {
  padding-top: 5rem !important;;
}

.wrapper-custom {
  margin-top: 60px;
}

.timeline-item .date {
  padding-top: 15px;
}

.timeline-item .content {
  border-right: 1px solid #e7eaec;
}


@media (min-width: 1378px) {
  .wrapper-custom {
    margin-top: 50px;
  }
}
@media (max-width: 1378px) {
  .wrapper-custom {
    margin-top: 110px;
  }
}

@media (max-width: 770px) {
  .wrapper-custom {
    margin-top: 135px;
  }
}

@media (max-width: 570px) {
  .wrapper-custom {
    margin-top: 170px;
  }
}

.responsive-image {
  max-width: 100%; /* Set maximum width to prevent image from exceeding container width */
  height: auto; /* Let the height adjust automatically to maintain aspect ratio */

  /* Optional: Add additional styling */
}

/* Media query for adjusting image size based on screen width */
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .responsive-image {
    width: 30%; height: auto;/* Adjust the width percentage as needed */
  }
}
@media (max-width: 570px) { /* Adjust the breakpoint as needed */
  .responsive-image {
    width: 20%;height: auto; /* Adjust the width percentage as needed */
  }
}

.responsive-image-col {
  width: 200px;
}

/* Media query for adjusting image size based on screen width */
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .responsive-image-col {
    width: 600px;
  }
}