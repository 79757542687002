.print {
  display: none;
}

@media print {
  a[href]:after {
    content: none !important;
  }

  h2 {
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
  }
  /* TABLES */
  /* .table-bordered {
    border: 1px solid #000 !important;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    background-color: #000 !important;
    border-bottom-width: 1px;
  }

  .table-bordered > thead > tr > th,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > td {
    border: none;
  }

  .table-sm {
    border: none;
  }

  .table-sm > thead > tr > th,
  .table-sm > thead > tr > td {
    background-color: #000 !important;
    border-bottom-width: 0;
  }

  .table-sm > thead > tr > th,
  .table-sm > tbody > tr > th,
  .table-sm > tfoot > tr > th,
  .table-sm > thead > tr > td,
  .table-sm > tbody > tr > td,
  .table-sm > tfoot > tr > td {
    border: none;
  }

  .table > thead > tr > th {
    border-bottom: 1px solid #000 !important;
    vertical-align: bottom;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    border-top: 1px solid #000 !important;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
  }

  th,
  td {
    border: solid #000 !important;
    border-width: 0 1px 1px 0 !important;
  } */

  @page {
    size: auto;
    margin: 2mm;
    align-items: center;
  }

  .ibox-center {
    margin-bottom: 15px;
    margin-top: 0;
    width: 100%;
    text-align: center;
  }

  .text-left h3 {
    text-align: left;
  }

  .print {
    display: block;
    margin-left: 3px;
  }

  .time-slot {
    position: inherit;
    height: 100px;
  }

  .time-slot p {
    margin: 0 0 5px;
  }

  .noprint {
    display: none;
  }

  body * {
    visibility: hidden;
    overflow: initial !important;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 2px;
    top: 0;
    right: 0;
    width: 100%;
  }

  .col-print-1 {
    width: 8%;
    float: left;
  }
  .col-print-2 {
    width: 16%;
    float: left;
  }
  .col-print-3 {
    width: 25%;
    float: left;
  }
  .col-print-4 {
    width: 33%;
    float: left;
  }
  .col-print-5 {
    width: 42%;
    float: left;
  }
  .col-print-6 {
    width: 50%;
    float: left;
  }
  .col-print-7 {
    width: 58%;
    float: left;
  }
  .col-print-8 {
    width: 66%;
    float: left;
  }
  .col-print-9 {
    width: 75%;
    float: left;
  }
  .col-print-10 {
    width: 83%;
    float: left;
  }
  .col-print-11 {
    width: 92%;
    float: left;
  }
  .col-print-12 {
    width: 100%;
    float: left;
  }

  .print-header {
    font-size: 20px;
  }
}
